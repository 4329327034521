import { IComponentsRegistrar } from '@wix/thunderbolt-components-loader'
import { named, optional, withDependencies } from '@wix/thunderbolt-ioc'
import {
	Experiments,
	ExperimentsSymbol,
	HeadContentSymbol,
	IHeadContent,
	SiteFeatureConfigSymbol,
	ViewMode,
	ViewModeSym,
	ViewerModel,
	ViewerModelSym,
} from '@wix/thunderbolt-symbols'
import { name, OOISsrManagerSymbol, ReactLoaderForOOISymbol } from './symbols'
import type { OOIComponentData, OOIComponentLoader, OOISiteConfig, OOISsrManager } from './types'
import { getCreateTpaWidgetNativeFactory } from './tpaWidgetNativeFactory'
import { reportError } from '@wix/thunderbolt-commons'
import { ComponentType } from 'react'
import LazySentry from './lazySentry'

export const FEED_WIDGET_ID_PROD = '14c1462a-97f2-9f6a-7bb7-f5541f23caa6'

export const ooiComponentsRegistrar = withDependencies(
	[
		named(SiteFeatureConfigSymbol, name),
		ReactLoaderForOOISymbol,
		ExperimentsSymbol,
		ViewModeSym,
		HeadContentSymbol,
		ViewerModelSym,
		optional(OOISsrManagerSymbol),
	],
	(
		{ ooiComponentsData, blogMobileComponentUrl }: OOISiteConfig,
		loader: OOIComponentLoader,
		experiments: Experiments,
		viewMode: ViewMode,
		headContent: IHeadContent,
		viewerModel: ViewerModel,
		ooiSsrManager?: OOISsrManager
	): IComponentsRegistrar => {
		const loadComponent = ({
			componentUrl: url,
			widgetId,
			staticBaseUrl,
			sentryDsn,
			isLoadable = false,
		}: OOIComponentData) => async () => {
			const staticBaseUrlFixed = staticBaseUrl?.endsWith('/') ? staticBaseUrl.slice(0, -1) : staticBaseUrl

			const shouldLoadBlogMobileComponentUrl =
				widgetId === FEED_WIDGET_ID_PROD &&
				experiments['specs.thunderbolt.blog_mobile_bundle_new'] &&
				viewMode === 'mobile'

			const componentUrl = shouldLoadBlogMobileComponentUrl ? blogMobileComponentUrl : url

			const forceLoadable = viewerModel.requestUrl.includes('forceLoadableWidget=true')
			const shouldUseLoadable = forceLoadable || isLoadable
			const createTpaWidgetNative = getCreateTpaWidgetNativeFactory(shouldUseLoadable)
			const loadReactComponent = async () => {
				try {
					const ReactComponent = (await loader.loadComponent(componentUrl)) as ComponentType<any>
					if (!ReactComponent) {
						reportError(new Error('component is not exported'), LazySentry, sentryDsn)
					}
					return ReactComponent
				} catch (error) {
					console.error(error) // verticals are not interested for network errors to be reported to their dsn
				}
			}
			const component = await createTpaWidgetNative({
				componentUrl,
				ooiSsrManager,
				headContent,
				staticBaseUrl: staticBaseUrlFixed,
				loader,
				sentryDsn,
				loadReactComponent,
			})

			return {
				component,
			}
		}
		return {
			registerComponents: (hostAPI) => {
				Object.entries(ooiComponentsData).forEach(
					([widgetId, { componentUrl, staticBaseUrl, sentryDsn, isLoadable }]) => {
						hostAPI.registerComponent(
							'tpaWidgetNative',
							loadComponent({ componentUrl, widgetId, staticBaseUrl, sentryDsn, isLoadable }),
							widgetId
						)
					}
				)
			},
		}
	}
)
